import React, { useCallback, useRef, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Hidden from '@material-ui/core/Hidden'
import { makeStyles } from '@material-ui/core/styles'

import { useIntl } from 'gatsby-plugin-intl'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import { Checkbox, useMediaQuery, useTheme } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import clsx from 'clsx'
import styled from 'styled-components'
import { CustomButton } from '../../CustomButton'
import api from '../../../utils/api'
import axios from 'axios'
import { toast } from 'react-toastify'
import { handleLeadSubmission, sendLeadEmail } from '../../../utils/helper'
import { EMAIL_SUPPORT } from '../../../utils/constants'

const Square = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #606060;
  margin: 2px;
  background: transparent;
`
const FullSquare = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid #606060;
  margin: 2px;
  background: #979797;
`
const SquareRed = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid;
  margin: 2px;
  background: transparent;
`

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingBlock: theme.spacing(10),
    backgroundColor: (props: any) => props.backgroundColor || '#FAE9E5',
    [theme.breakpoints.between('xs', 'md')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  innerDiv: {
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    padding: '0em 1em',
    width: '55vw',
    [theme.breakpoints.down('sm')]: {
      flexBasis: 'unset',
      width: '100%',
    },
  },

  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '58px',
    paddingInline: '3rem',
    fontSize: theme.typography.pxToRem(52),
    fontWeight: 400,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(40),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(35),
    },
  },
  subtitle: {
    fontFamily: `'Gotham Light', serif`,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 700,
    lineHeight: '20px',
    marginBottom: theme.spacing(5),
  },
  detail: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  message: {
    fontFamily: `'Gotham Light', serif`,
    marginTop: '1.5rem',
    paddingBottom: '0',
    fontWeight: 325,
    fontSize: '12px',
    textAlign: 'left',
  },
  ml1: {
    marginLeft: '1em',
  },
  linksText: {
    fontFamily: `'Gotham Light', serif`,
    fontSize: '14px',
    fontWeight: 325,
    lineHeight: '16.8px',
    color: theme.palette.text.primary,
    textDecoration: 'unset',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  containerClass: {
    marginBottom: 30,
    flex: 1,
  },
  transparent: {
    backgroundColor: 'transparent',
  },
  w100: {
    width: '100%',
  },
  mb2: {
    marginInline: theme.spacing(4),
    marginBottom: theme.spacing(10),
  },

  inputClass: {
    '& input': {
      fontFamily: `'Gotham Book', serif`,
      fontSize: '.8rem',
      color: '#000',
    },
    '&::placeholder': {
      fontFamily: "'Gotham Light', serif",
      fontSize: '12px',
      fontWeight: 325,
      lineHeight: '14.4px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: theme.palette.primary,
    },
    '& .MuiInputBase-input': {
      fontFamily: `'Gotham Book', serif`,
      fontSize: '.8rem',
      color: '#818181',
    },
    '& .MuiInputBase-input::placeholder': {
      fontFamily: `'Gotham Light', serif`,
      fontSize: '12px',
      fontWeight: 325,
      color: theme.palette.primary,
    },
    '& .MuiInput-underline:before': {
      borderBottom: '1px solid #000', // Ligne sous l'input
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid #000', // Ligne sous l'input au survol
    },
    '& .MuiInput-underline:after': {
      borderBottom: '2px solid #000', // Ligne active
    },
  },
  inputPlaceholder: {
    color: '#fff',
    '&::placeholder': {
      fontFamily: "'Gotham Light', serif",
      fontSize: '12px',
      fontWeight: 325,
      lineHeight: '14.4px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: theme.palette.primary,
    },
    '&.MuiInputBase-input::placeholder': {
      fontFamily: `'Gotham Light', serif`,
      fontSize: '12px',
      fontWeight: 325,
      lineHeight: '14.4px',
      textAlign: 'left',
      textUnderlinePosition: 'from-font',
      textDecorationSkipInk: 'none',
      color: theme.palette.primary,
    },
  },
  btnSection: {
    justifyContent: 'center',
    marginBlock: theme.spacing(5),
  },
  consentBox: {
    paddingInline: '0rem 4rem',
    textAlign: 'left',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingInline: '0rem',
      display: 'flex',
      flexDirection: 'row',
    },
  },
}))

const ContactForm = ({
  title,
  subtitle,
  backgroundColor,
}: {
  title?: string
  subtitle?: string
  backgroundColor?: string
}) => {
  const classes = useStyles({ backgroundColor })
  const intl = useIntl()
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    form: 'FE1',
    consent: false,
  }
  const [submited, setSubmited] = useState(false)
  const [error, setError] = useState(['initial'])
  const [inputError, setInputError] = useState<Record<string, string>>({})

  const [state, setState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
    form: 'FE1',
    consent: false,
  } as any)

  const handleChange = useCallback(
    (
      event: React.ChangeEvent<{
        name?: string
        value: unknown
        textContent?: any
        checked?: boolean
      }>,
    ) => {
      if (event && event.target && (event.target.name || event.target.textContent)) {
        setError([])
        setState({
          ...state,
          [event.target.name || ('adresse' as string)]:
            (event.target.name == 'consent' ? event.target.checked : event.target.value) ||
            event.target.textContent,
        })
      }
    },
    [state],
  ) as any

  const onSubmit = async () => {
    const { message, consent, ...keyToValidate } = state

    const validateField = Object.keys(keyToValidate).filter((key: string) => {
      return !state[key] // Retourne les champs vides
    })

    if (validateField.length) {


      setError(validateField)
    } else {
      const detailedMesg = `

          consent : ${state.consent ? true : false} , \n
          message : ${state.message} , \n
          `
      const payload = {
        simpleLead: {

          source: 'Kretz - Contact',
          contactFullName: state.firstName + ' ' + state.lastName,
          contactPhoneNumber: state.phone,
          contactEmail: state.email,
          message: detailedMesg,
        },
      }

      try {
        // Handle lead submission using helper method
        const leadResult = await handleLeadSubmission(payload);

        if (leadResult.success) {
          setError([])
          setSubmited(false)
          setState(initialState)
          toast.success(intl.formatMessage({ id: 'new.toast_ok' }), {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: true,
          });

          // Format email in English
          const formattedEmailMsg = `
<!DOCTYPE html>
<html>
<head>
  <meta charset="UTF-8">
  <meta name="viewport" content="width=device-width, initial-scale=1.0">
  <title>Contact Request</title>
</head>
<body style="margin: 0; padding: 0; font-family: Arial, sans-serif; background-color: #f4f4f4;">
  <table border="0" cellpadding="0" cellspacing="0" width="100%" style="max-width: 600px; margin: 0 auto; background-color: #ffffff; border-collapse: collapse;">
    <tr>
      <td style="padding: 20px; text-align: left;">
        <h2 style="color: #333333; margin-bottom: 20px;">New Contact Request</h2>
        <p style="margin-bottom: 20px;">A new contact request has been submitted:</p>
        
        <table border="0" cellpadding="0" cellspacing="0" width="100%" style="border-collapse: collapse;">
          <tr>
            <td style="padding: 10px; border-bottom: 1px solid #eeeeee;"><strong>Name:</strong></td>
            <td style="padding: 10px; border-bottom: 1px solid #eeeeee;">${state.lastName} ${state.firstName}</td>
          </tr>
          <tr>
            <td style="padding: 10px; border-bottom: 1px solid #eeeeee;"><strong>Phone:</strong></td>
            <td style="padding: 10px; border-bottom: 1px solid #eeeeee;">${state.phone}</td>
          </tr>
          <tr>
            <td style="padding: 10px; border-bottom: 1px solid #eeeeee;"><strong>Email:</strong></td>
            <td style="padding: 10px; border-bottom: 1px solid #eeeeee;">${state.email}</td>
          </tr>
          <tr>
            <td style="padding: 10px; border-bottom: 1px solid #eeeeee;"><strong>Message:</strong></td>
            <td style="padding: 10px; border-bottom: 1px solid #eeeeee;">${state.message}</td>
          </tr>
        </table>
        
        <p style="margin-top: 20px;">Please respond to this inquiry as soon as possible.</p>
        
        <p style="margin-top: 30px; padding-top: 10px; border-top: 1px solid #eeeeee; color: #777777;">
          Best regards,<br>
          The Kretz Team
        </p>
      </td>
    </tr>
  </table>
</body>
</html>
`;


          // Send email using helper method
          const emailResult = await sendLeadEmail(
            formattedEmailMsg,
            payload?.simpleLead?.source,
            EMAIL_SUPPORT
          );

          if (!emailResult.success) {
            console.log("Email sending failed:", emailResult.error);
          }
        } else {
          throw new Error(leadResult.error?.message || 'Lead submission failed');
        }
      } catch (error) {
        toast.error(intl.formatMessage({ id: 'new.toast_error' }), {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: true,
        });
      }
    }
  }

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.innerDiv}>
        <Grid item xs={12}>
          <Grid className={classes.title}>
            <span>{title || intl.formatMessage({ id: 'new.Contact_2' })}</span>
          </Grid>
          {subtitle && (
            <Grid className={classes.subtitle}>
              <span>{subtitle}</span>
            </Grid>
          )}
        </Grid>
        <Grid container xs={12} md={12} className={classes.detail} spacing={8}>
          <Grid item lg={6}>
            <TextField
              value={state.firstName}
              placeholder={intl.formatMessage({ id: 'new.Formulaire estimation_12' })}
              name="firstName"
              error={error.includes('firstName')}
              helperText={inputError.firstName}
              onChange={handleChange}
              InputProps={{
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
              className={clsx(classes.w100, classes.inputClass)}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              variant="standard"
              value={state.lastName}
              placeholder={intl.formatMessage({ id: 'new.Formulaire estimation_13' })}
              name="lastName"
              error={error.includes('lastName')}
              onChange={handleChange}
              InputProps={{
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
              className={clsx(classes.w100, classes.inputClass)}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              variant="standard"
              value={state.phone}
              placeholder={intl.formatMessage({ id: 'yourNumber' })}
              name="phone"
              error={error.includes('phone')}
              onChange={handleChange}
              InputProps={{
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
              className={clsx(classes.w100, classes.inputClass)}
            />
          </Grid>
          <Grid item lg={6}>
            <TextField
              variant="standard"
              value={state.email}
              placeholder={intl.formatMessage({ id: 'yourEmail' })}
              name="email"
              error={error.includes('email')}
              onChange={handleChange}
              InputProps={{
                classes: {
                  input: classes.inputPlaceholder,
                },
              }}
              className={clsx(classes.w100, classes.inputClass)}
            />
          </Grid>
          <Grid xs={12} item className={clsx(classes.message)}>
            {intl.formatMessage({ id: 'yourMessage' })}...
          </Grid>
          <TextareaAutosize
            value={state.message}
            className={clsx(classes.w100, classes.transparent, classes.mb2)}
            aria-label="minimum height"
            name="message"
            onChange={handleChange}
            minRows={18}
            style={{ resize: 'none' }}
          />
        </Grid>
        <Grid container md={12} sm={12} className={classes.consentBox}>
          <Grid item xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
            <Checkbox
              size="small"
              icon={
                state.consent == false && !error.includes('initial') ? <SquareRed /> : <Square />
              }
              checkedIcon={<FullSquare />}
              checked={state.consent}
              onChange={handleChange}
              name={'consent'}
              style={{ padding: '0 0 0 0px' }}
            />
          </Grid>
          <Grid item xs={11}>
            {intl.formatMessage({ id: 'legalConsent.estimate.agree' })}
          </Grid>
        </Grid>
        <Grid container md={12} style={{ marginTop: '1em', textAlign: 'left' }}>
          <Grid item xs={1}></Grid>
          <Grid item xs={11}>
            {intl.formatMessage({ id: 'legalConsent.estimate.moreInformation' })}
          </Grid>
        </Grid>
        <Grid container md={12} className={classes.btnSection}>
          <CustomButton
            onClick={onSubmit}
            darkBoarder
            width={isSm ? '100%' : '25%'}
            textTransform="uppercase"
            isDarkBackground={true}
          >
            <span style={{ fontSize: '0.8rem', padding: '0 0px', fontWeight: 100 }}>
              {intl.formatMessage({ id: 'send' })}
            </span>
          </CustomButton>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ContactForm
